import { defineStore } from 'pinia'

import DeviceService from '@/apps/dailyTech/inventario-ti/services/devices.service'

const _DeviceService = new DeviceService()

export const useFieldsInventoryStore = defineStore({
  id: 'fieldsInventory',
  state: () => ({
    _infoFields: {
      id: null,
      nameField: null,
      showName: null,
      dataType: null,
      description: null,
      choiceList: null,
      numberFormat: null,
      isRequired: false,
      isUnique: false,
      isReadOnly: false,
      isMultiple: false,
      status: true,
      typeDevices: null
    },
    _listTypeFields: [
      { label: 'Fecha (date)', value: 'date' },
      { label: 'Lista (select)', value: 'select' },
      { label: 'Número (number)', value: 'number' },
      { label: 'Booleano (true/false)', value: 'boolean' },
      { label: 'Texto corto (text)', value: 'text' },
      { label: 'Tiempo (time)', value: 'time' },
      { label: 'Fecha y tiempo (datetime)', value: 'datetime' },
      { label: 'Correo (email)', value: 'email' },
      { label: 'Teléfono (phone)', value: 'phone' },
      { label: 'URL', value: 'url' },
      { label: 'Imagen (image)', value: 'image' },
      { label: 'Archivo (file)', value: 'file' },
      { label: 'Color', value: 'color' },
      { label: 'Contraseña (password)', value: 'password' },
      { label: 'Lista de chequeo (checkbox)', value: 'checkbox' },
      { label: 'Botón de radio (radio)', value: 'radio' },
      { label: 'Lista multiple (multiselect)', value: 'multiselect' },
      { label: 'Texto Largo (textarea)', value: 'textarea' }
    ],
    _listTypeNumberFormat: [
      { label: 'Decimal', value: 'decimal' },
      { label: 'Entero', value: 'integer' },
      { label: 'Moneda', value: 'currency' },
      { label: 'Porcentaje', value: 'percent' }
    ],
    _listTypeDevices: [],
    _formSubmitted: false,
    _isValidForm: true,
    _messageErrors: [],
    _refrescarListadoFields: false
  }),
  getters: {
    getInfoFields: (state) => state._infoFields,
    getListDataType: (state) => state._listTypeFields,
    getListTypeDevices: (state) => state._listTypeDevices,
    getListTypeNumberFormat: (state) => state._listTypeNumberFormat,
    getFormSubmitted: (state) => state._formSubmitted,
    getIsValidForm: (state) => state._isValidForm,
    getMessageErrors: (state) => state._messageErrors,
    getRefrescarListadoFields: (state) => state._refrescarListadoFields
  },
  actions: {
    setInfoFields (infoFields) {
      this._infoFields = infoFields
    },
    setListTypeFields (listTypeFields) {
      this._listTypeFields = listTypeFields
    },
    setListTypeDevices (listTypeDevices) {
      this._listTypeDevices = listTypeDevices
    },
    setListTypeNumberFormat (listTypeNumberFormat) {
      this._listTypeNumberFormat = listTypeNumberFormat
    },
    setFormSubmitted (formSubmitted) {
      this._formSubmitted = formSubmitted
    },
    setIsValidForm (isValidForm) {
      this._isValidForm = isValidForm
    },
    setMessageErrors (messageErrors) {
      this._messageErrors = messageErrors
    },
    setRefrescarListadoFields (refrescarListadoFields) {
      this._refrescarListadoFields = refrescarListadoFields
    },
    async _clearInfoFields () {
      this._infoFields = {
        id: null,
        nameField: null,
        showName: null,
        dataType: null,
        description: null,
        choiceList: null,
        numberFormat: null,
        isRequired: false,
        isUnique: false,
        isReadOnly: false,
        isMultiple: false,
        status: true,
        typeDevices: null
      }
      this._formSubmitted = false
      this._isValidForm = true
      this._messageErrors = []
    },
    async _actionTypeDevices () {
      try {
        const { data } = await _DeviceService.getDevices()
        this.setListTypeDevices(data)
      } catch (error) {
        console.error('Error al obtener los tipos de dispositivos: ', error)
      }
    }
  }
})
