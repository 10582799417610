<script setup>
import { onMounted, ref, watch } from 'vue'
import SidebarFields from '@/apps/dailyTech/inventario-ti/views/configuracion/campos/components/sidebarFields.vue'
import FieldsService from '@/apps/dailyTech/inventario-ti/services/fields.service'
import Icon from '@/components/Icon/index.vue'
import { FilterMatchMode } from 'primevue/api'
import { useFieldsInventoryStore } from '@/apps/dailyTech/inventario-ti/stores/fieldsInventory.store'
import { storeToRefs } from 'pinia'
import Swal from 'sweetalert2'
import { useToast } from 'primevue/usetoast'

const toast = useToast()
const fieldsInventoryStore = useFieldsInventoryStore()
const {
  _listTypeFields,
  _listTypeNumberFormat,
  _refrescarListadoFields
} = storeToRefs(fieldsInventoryStore)
const _FieldsService = ref(new FieldsService())
const refSidebarField = ref(null)
const listFields = ref([])
const filters = ref({
  global: { value: null, matchMode: FilterMatchMode.CONTAINS }
})
const opChoiceList = ref()
const infoChoiceList = ref([])

// Abrir OverlayPanel para ver la lista de opciones
const toggleChoiceList = (event, data) => {
  infoChoiceList.value = data.choiceList
  opChoiceList.value.toggle(event)
}

// Abrir el sidebar del campo
const onSidebarField = (data) => {
  refSidebarField.value.openSidebarField(data)
}

// Obtener el listado de los campos
const fetchFields = async () => {
  try {
    const { status, data } = await _FieldsService.value.getFields()
    if (status === 200) {
      listFields.value = data

      // Mapear la data
      listFields.value.forEach((field) => {
        field.dataTypeName = _listTypeFields.value.find((typeField) => typeField.value === field.dataType).label
        field.numberFormatName = field.numberFormat ? _listTypeNumberFormat.value.find((typeNumberFormat) => typeNumberFormat.value === field.numberFormat).label : ''
      })
    }
  } catch (error) {
    console.log('Error al obtener los campos', error.message)
  }
}

// Eliminar un campo
const deleteField = async (data) => {
  try {
    const { id } = data

    if (id) {
      Swal.fire({
        icon: 'warning',
        title: 'Advertencia',
        text: 'Al eliminar este campo, se perderá toda la información sobre activos almacenada en esos campos. ¿Está seguro?.',
        showDenyButton: true,
        denyButtonText: 'No, Cancelar',
        confirmButtonText: 'Si, Eliminar'
      }).then((result) => {
        if (result.isConfirmed) {
          _FieldsService.value.deleteField(id).then(({ status }) => {
            if (status !== 200) return

            toast.add({ severity: 'success', summary: 'Exito', detail: 'Campo eliminado exitosamente', life: 3000 })
            fetchFields()
          }).catch((err) => {
            console.error(err.message)
            if (err.response.status) {
              toast.add({ severity: 'error', summary: 'Error', detail: 'No se ha podido eliminar el campo', life: 3000 })
            }
          })
        }
      })
    } else {
      toast.add({ severity: 'error', summary: 'Error', detail: 'No se ha podido eliminar el campo', life: 3000 })
    }
  } catch (error) {
    console.error(error.message)
  }
}

// Watch para refrescar el listado
watch(() => _refrescarListadoFields.value, async (value) => {
  if (value) {
    await fetchFields()
    fieldsInventoryStore.setRefrescarListadoFields(false)
  }
})

// Hook onMounted
onMounted(async () => {
  await fetchFields()
  await fieldsInventoryStore._actionTypeDevices()
})
</script>

<template>
  <Toolbar class="box mt-4 py-2 px-4">
    <template #start>
      <Button
        label="Regresar"
        icon="pi pi-arrow-left"
        class="p-button-sm"
        @click="$router.push({ name: 'daily-tech.inventario-ti.configuracion'})"/>
    </template>
    <template #end>
      <Button
        label="Agregar"
        icon="pi pi-plus"
        size="small"
        severity="success"
        @click="onSidebarField(null)"
      />
    </template>
  </Toolbar>

  <!-- Listado de los campos -->
  <div class="box mt-4 intro-x">
    <DataTable
      showGridlines
      ref="dt"
      :value="listFields"
      class="p-datatable-sm p-4 text-sm"
      dataKey="id"
      :paginator="true"
      :rows="10"
      :filters="filters"
      sortField="id"
      :sortOrder="-1"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      :rowsPerPageOptions="[5,10,25,50]"
      currentPageReportTemplate="Mostrando registros del {first} al {last} de un total de {totalRecords} registros"
      responsiveLayout="scroll"
    >
      <template #header>
        <div class="table-header p-d-flex p-flex-column p-flex-md-row p-jc-md-between">
          <h3 class="p-mb-2 p-m-md-0 p-as-md-center text-lg">Listado de campos</h3>
          <span class="p-input-icon-left">
            <i class="pi pi-search" />
            <InputText v-model="filters['global'].value" placeholder="Buscar..." />
          </span>
        </div>
      </template>

      <!-- Columnas -->
      <template #empty>
        <div class="flex flex-col items-center justify-center" style="height: 500px">
          <Icon icon="flat-color-icons:multiple-inputs" class="text-gray-200" style="font-size: 15rem; opacity: 0.3"/>
          <span class="text-gray-400 text-2xl">No se encontraron registros</span>
          <span class="text-gray-300">No hemos encontrado ningún elemento, pruebe con otro filtro u opción</span>
        </div>
      </template>
      <template #loading> Cargando la información, por favor espere... </template>
      <Column field="id" header="Id" :sortable="true"></Column>
      <Column field="showName" header="Nombre" :sortable="true">
        <template #body="{data}">
          <span class="text-blue-800 font-semibold cursor-pointer" @click="onSidebarField(data)">{{ data.showName }}</span>
        </template>
      </Column>
      <Column field="showDevices" header="Dispositivos" :sortable="true">
        <template #body="{data}">
          <span v-if="data.isGlobal">Todos los dispositivos</span>
          <span v-else-if="data.fieldDevices.length === 0 && !data.isGlobal" class="italic text-gray-400">Sin asignar</span>
          <span v-else>{{ data.fieldDevices.map((device) => device.devicename).join(', ') }}</span>
        </template>
      </Column>
      <Column field="dataTypeName" header="Tipo" :sortable="true">
        <template #body="{data}">
          <div class="flex flex-col">
            <span>{{ data.dataTypeName }}</span>
            <span class="text-gray-400 text-xs">{{ data.numberFormatName }}</span>
            <div
              v-if="['select', 'checkbox', 'radio', 'multiselect'].includes(data.dataType) && data.choiceList"
              class="flex flex-wrap items-start cursor-pointer"
              @click="toggleChoiceList($event, data)"
            >
              <span class="border-b border-dashed border-blue-600 text-blue-600 text-xs">Ver opciones</span>
            </div>
            <OverlayPanel
              v-if="['select', 'checkbox', 'radio', 'multiselect'].includes(data.dataType) && data.choiceList"
              ref="opChoiceList"
              :pt="{
                root: {
                  style: 'width: 300px; margin-top: 0'
                },
                content: {
                  style: 'padding: 1rem; border-radius: 0.5rem; box-shadow: 0 0 10px rgba(0, 0, 0, 0.1)'
                }
              }"
            >
              <div class="flex flex-col">
                <span class="text-sm font-semibold">Listado de opciones</span>
                <DataTable
                  :value="infoChoiceList"
                  class="p-datatable-sm py-2 text-xs"
                  scrollable
                  scrollHeight="300px"
                >
                  <Column field="label" header="Valor"></Column>
                </DataTable>
              </div>
            </OverlayPanel>
          </div>
        </template>
      </Column>
      <Column field="description" header="Descripción" :sortable="true"></Column>
      <Column field="isRequired" header="¿Es Requerido?" :sortable="true">
        <template #body="{data}">
          <Icon v-if="data.isRequired" icon="mdi:check-circle" class="text-green-600 text-2xl" />
          <Icon v-else icon="mdi:close-circle" class="text-red-600 text-2xl" />
        </template>
      </Column>
      <Column field="isUnique" header="¿Es único?" :sortable="true">
        <template #body="{data}">
          <Icon v-if="data.isUnique" icon="mdi:check-circle" class="text-green-600 text-2xl" />
          <Icon v-else icon="mdi:close-circle" class="text-red-600 text-2xl" />
        </template>
      </Column>
      <Column field="status" header="Estado" :sortable="true" style="width: 8rem; max-width: 8rem">
        <template #body="{data}">
          <Badge :severity="data.status ? 'success' : 'danger'" :value="data.status ? 'Activo' : 'Inactivo'" />
        </template>
      </Column>
      <Column :exportable="false" header="Acciones" style="text-align: center; width: 10rem; max-width: 10rem">
        <template #body="{ data }">
          <Button icon="pi pi-pencil" class="p-button-rounded p-button-warning p-mr-2" @click="onSidebarField(data)" />
          <Button icon="pi pi-trash" class="p-button-rounded p-button-danger" @click="deleteField(data)" />
        </template>
      </Column>
    </DataTable>
  </div>

  <SidebarFields ref="refSidebarField" />
</template>

<style scoped>

</style>
