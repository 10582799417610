<script setup>
import { defineExpose, onMounted, ref } from 'vue'
import { useFieldsInventoryStore } from '@/apps/dailyTech/inventario-ti/stores/fieldsInventory.store'
import FieldsService from '@/apps/dailyTech/inventario-ti/services/fields.service'
import { storeToRefs } from 'pinia'
import { useToast } from 'primevue/usetoast'
import Swal from 'sweetalert2'

const toast = useToast()
const isEdit = ref(false)
const _FieldService = ref(new FieldsService())
const fieldsInventoryStore = useFieldsInventoryStore()
const {
  _listTypeDevices,
  _listTypeFields,
  _infoFields,
  _listTypeNumberFormat,
  _formSubmitted,
  _isValidForm,
  _messageErrors
} = storeToRefs(fieldsInventoryStore)
const visible = ref(false)
const sidebarPosition = ref(window.innerWidth > 1024 ? 'right' : 'full')
const sidebarClass = ref(window.innerWidth > 1024 ? 'p-sidebar-md' : '')
const sidebarStyle = ref(window.innerWidth > 1024 ? 'width: 50vw' : '')

// Abrir el sidebar
const openSidebarField = async (data) => {
  visible.value = true
  await fieldsInventoryStore._actionTypeDevices()

  console.log('openSidebarField...', data)
  if (data) {
    _infoFields.value = data

    // Recorrer infoFileds para convertir los valores de typeDevices a un array
    if (data.fieldDevices.length > 0) {
      _infoFields.value.typeDevices = data.fieldDevices.map((device) => device.deviceId)
    } else {
      _infoFields.value.typeDevices = []
    }

    if (data.dataType === 'select' || data.dataType === 'checkbox' || data.dataType === 'radio' || data.dataType === 'multiselect') {
      _infoFields.value.choiceList = data.choiceList.map((choice) => choice.label)
    } else {
      _infoFields.value.choiceList = []
    }

    isEdit.value = true
  } else {
    await fieldsInventoryStore._clearInfoFields()
    isEdit.value = false
  }
}

// Evento o funcion para limpiar el store cuando se cierra el sidebar
const onCloseSidebar = () => {
  console.log('onCloseSidebar...')
  visible.value = false
  fieldsInventoryStore._clearInfoFields()
  fieldsInventoryStore.setRefrescarListadoFields(true)
}

// Validar los campos
const validateFields = () => {
  const validations = {
    showName: 'El campo <span class="text-red-600 font-bold">Nombre</span> es requerido',
    description: 'El campo <span class="text-red-600 font-bold">Descripción</span> es requerido',
    dataType: 'El campo <span class="text-red-600 font-bold">Tipo de campo</span> es requerido'
  }

  let isFormValid = true

  for (const field in validations) {
    console.log('field', field, _infoFields.value[field])
    if (field === 'dataType' && ['select', 'checkbox', 'radio', 'multiselect'].includes(_infoFields.value[field]) && ([null, undefined, ''].includes(_infoFields.value.choiceList) || _infoFields.value.choiceList.length === 0)) {
      _messageErrors.value.push('El campo <span class="text-red-600 font-bold">Opciones de la lista</span> es requerido')
      isFormValid = false
    }

    if (field === 'dataType' && _infoFields.value[field] === 'number' && ([null, undefined, ''].includes(_infoFields.value.numberFormat))) {
      _messageErrors.value.push('El campo <span class="text-red-600 font-bold">Formato tipo número</span> es requerido')
      isFormValid = false
    }

    if (!_infoFields.value[field]) {
      _messageErrors.value.push(validations[field])
      isFormValid = false
    }
  }

  return isFormValid
}

// Funcion para enviar el submit de los campos
const onSaveField = async () => {
  _isValidForm.value = true
  _formSubmitted.value = true
  _messageErrors.value = []

  if (!validateFields()) {
    _isValidForm.value = false
    toast.add({ severity: 'error', summary: 'Error', detail: 'Por favor, complete los campos requeridos', life: 3000 })
    return
  }

  const message = isEdit.value ? '¿Desea actualizar el campo?' : '¿Desea guardar el campo?'

  Swal.fire({
    icon: 'info',
    title: 'Información',
    text: message,
    showConfirmButton: true,
    confirmButtonColor: '#24963E',
    confirmButtonText: 'Si, Guardar!',
    showCancelButton: true,
    cancelButtonColor: '#d33',
    cancelButtonText: 'No, cancelar!'
  }).then(async (resp) => {
    if (resp.isConfirmed) {
      if (isEdit.value) {
        const { id } = _infoFields.value

        await _FieldService.value.editField(id, _infoFields.value).then((resp) => {
          const { data } = resp
          if (!data.success) {
            toast.add({ severity: 'error', summary: 'Error', detail: data.message, life: 3000 })
            return
          }

          onCloseSidebar()
          toast.add({ severity: 'success', summary: 'Actualizado', detail: 'El campo se actualizó correctamente', life: 3000 })
        }).catch((error) => {
          toast.add({ severity: 'error', summary: 'Error', detail: 'Ocurrió un error al actualizar el campo', life: 3000 })
          console.log('Error al actualizar el campo --->', error)
        })
        return
      }

      await _FieldService.value.createField(_infoFields.value).then((resp) => {
        const { data } = resp
        if (!data.success) {
          toast.add({ severity: 'error', summary: 'Error', detail: data.message, life: 3000 })
          return
        }

        onCloseSidebar()
        toast.add({ severity: 'success', summary: 'Guardado', detail: 'El campo se guardó correctamente', life: 3000 })
      }).catch((error) => {
        toast.add({ severity: 'error', summary: 'Error', detail: 'Ocurrió un error al guardar el campo', life: 3000 })
        console.log('Error al guardar el campo --->', error)
      })
    }
  })
}

//
window.addEventListener('resize', evt => {
  sidebarPosition.value = window.innerWidth > 1024 ? 'right' : 'full'
  sidebarClass.value = window.innerWidth > 1024 ? 'p-sidebar-md' : ''
  sidebarStyle.value = window.innerWidth > 1024 ? 'width: 50vw' : ''
})

// Se expone la funcion para abrir el sidebar, para que pueda ser llamado desde el componente padre
defineExpose({
  openSidebarField
})

// Onmounted
onMounted(() => {
  console.log('onMounted, sidebar...')
})
</script>

<template>
  <Toast />
  <div class="card flex justify-content-center">
    <Sidebar
      v-model:visible="visible"
      :position="sidebarPosition"
      :baseZIndex="1000"
      :class="sidebarClass"
      :style="sidebarStyle"
      @hide="onCloseSidebar"
      :dismissable="false"
      :pt="{
        header: {
          style: 'display: flex; align-items: center; justify-content: space-between;'
        }
      }"
    >
      <template #header>
        <Button
          label="Guardar"
          size="small"
          severity="success"
          icon="pi pi-save"
          @click="onSaveField"
          v-tippy="{ content: 'Guardar campo' }"
        />
      </template>

      <div class="flex flex-col sm:flex-row items-center justify-between mb-4">
        <h2 :class="{'text-yellow-700': isEdit, 'text-green-700': !isEdit}" class="font-bold text-gray-700 text-xl">{{isEdit ? 'Editar' : 'Crear'}} campo</h2>
        <div class="flex flex-col items-end">
          <span :class="`text-sm font-medium py-0.5 rounded-full`">Configuración de un campo personalizado.</span>
          <!-- Mensajes de error -->
          <div class="col-span-12 sm:col-span-12" v-if="!_isValidForm && _formSubmitted">
            <Badge
              :value="`Por favor introduzca los campos requeridos`"
              style="height: 1.2rem; font-size: 0.7rem; line-height: 1.2rem;"
              class="bg-red-100 text-red-900 capitalize cursor-pointer font-bold"
              v-tippy="{ content: _messageErrors.join('<br>') }"
            />
          </div>
        </div>
      </div>

      <div class="grid grid-cols-12 gap-2">
        <div class="col-span-12 lg:col-span-12">

          <div class="flex flex-col">

            <!-- Nombre del campo  -->
            <div class="flex flex-col sm:flex-row border-b py-4">
              <div class="w-full sm:w-1/5 md:w-1/5 lg:w-1/5 sm:px-5 flex-shrink-0 flex-grow-0 min-w-0 flex flex-col items-end justify-center">
                <label class="text-gray-500 text-sm font-semibold text-left w-full sm:text-right ml-2">Nombre: <span class="text-red-600">*</span></label>
              </div>
              <div class="min-w-0 w-full sm:w-4/5 md:w-4/5 lg:w-4/5 flex-grow flex-shrink sm:px-5 sm:border-l sm:border-gray-200 relative">
                <InputText
                  class="w-full"
                  placeholder="Nombre del campo"
                  v-model="_infoFields.showName"
                  :class="{ 'border-red-600': _formSubmitted && [null, ''].includes(_infoFields.showName) }"
                  :pt="{
                    root: { style: 'height: 2rem; font-size: 0.875rem;' + (_formSubmitted && [null, ''].includes(_infoFields.showName) ? ' border: 1px solid #e53e3e !important' : '') }
                  }"
                />
                <span class="mt-2 text-xs">Ingresa el nombre del campo</span>
              </div>
            </div>

            <!-- Descripción del campo  -->
            <div class="flex flex-col sm:flex-row border-b py-4">
              <div class="w-full sm:w-1/5 md:w-1/5 lg:w-1/5 sm:px-5 flex-shrink-0 flex-grow-0 min-w-0 flex flex-col items-end justify-center">
                <label class="text-gray-500 text-sm font-semibold text-left w-full sm:text-right ml-2">Descripción: <span class="text-red-600">*</span></label>
              </div>
              <div class="min-w-0 w-full sm:w-4/5 md:w-4/5 lg:w-4/5 flex-grow flex-shrink sm:px-5 sm:border-l sm:border-gray-200 relative">
                <Textarea
                  v-model="_infoFields.description"
                  rows="3"
                  cols="20"
                  class="w-full"
                  :pt="{
                    root: { style:  _formSubmitted && [null, ''].includes(_infoFields.description) ? 'border: 1px solid #e53e3e !important' : '' }
                  }"
                />
                <span class="mt-2 text-xs">Ingresa una breve descripción sobre los usos del campo.</span>
              </div>
            </div>

            <!-- Tipos de dispositivos  -->
            <div class="flex flex-col sm:flex-row border-b py-4">
              <div class="w-full sm:w-1/5 md:w-1/5 lg:w-1/5 sm:px-5 flex-shrink-0 flex-grow-0 min-w-0 flex flex-col items-end justify-center">
                <label class="text-gray-500 text-sm font-semibold text-left w-full sm:text-right ml-2">Tipos de dispositivos:</label>
                <span v-if="!_infoFields.typeDevices && _infoFields.fieldDevices && _infoFields.fieldDevices.length === 0 && !_infoFields.isGlobal" class="text-red-600 text-xs">Sin asignar</span>
              </div>
              <div class="min-w-0 w-full sm:w-4/5 md:w-4/5 lg:w-4/5 flex-grow flex-shrink sm:px-5 sm:border-l sm:border-gray-200 relative">
                <MultiSelect
                  id="typeDevice"
                  v-model="_infoFields.typeDevices"
                  :options="_listTypeDevices"
                  optionValue="id"
                  optionLabel="devicename"
                  placeholder="Todos los dispositivos"
                  scrollHeight="300px"
                  filter
                  class="w-full text-sm"
                  display="chip"
                  :pt="{
                    root: ({
                      props, state, context
                    }) => ({
                      style: 'height: 32px;'
                    }),
                    label: { style: 'font-size: 0.875rem; padding: 0.25rem 0.5rem' },
                    token: { style: 'font-size: 0.6rem; padding: 0.1rem 0.5rem' },
                    header: { style: 'font-size: 0.7rem; padding: 0.5rem 1rem' },
                    item: { style: 'font-size: 0.7rem; padding: 0.45rem 1rem' },
                    filterInput: { style: 'font-size: 0.7rem; padding: 0.3rem 1rem' }
                  }"
                >
                </MultiSelect>
                <span class="mt-2 text-xs">Seleccione los tipos de dispositivos para asignar el campo. Si selecciona "Todos", este campo también se aplicará a tipos futuros.</span>
              </div>
            </div>

            <!-- Tipo de campo (fecha, lista, numero, texto ) -->
            <div class="flex flex-col sm:flex-row border-b py-4">
              <div class="w-full sm:w-1/5 md:w-1/5 lg:w-1/5 sm:px-5 flex-shrink-0 flex-grow-0 min-w-0 flex flex-col items-end justify-center">
                <label class="text-gray-500 text-sm font-semibold text-left w-full sm:text-right ml-2">Tipo de campo: <span class="text-red-600">*</span></label>
              </div>
              <div class="min-w-0 w-full sm:w-4/5 md:w-4/5 lg:w-4/5 flex-grow flex-shrink sm:px-5 sm:border-l sm:border-gray-200 relative">
                <Dropdown
                  id="brand"
                  v-model="_infoFields.dataType"
                  :options="_listTypeFields"
                  optionLabel="label"
                  optionValue="value"
                  placeholder="Seleccione una opción"
                  :filter="true"
                  scrollHeight="300px"
                  filterPlaceholder="Buscar por nombre"
                  autoFilterFocus
                  class="w-full"
                  :class="{ 'border-red-600': _formSubmitted && !_infoFields.dataType }"
                  :pt="{
                    root: { style: 'height: 32px' },
                    input: { style: 'font-size: 0.875rem; padding: 0.25rem 0.5rem' },
                    panel: { style: 'font-size: 0.75rem; max-width: 100px;' },
                    item: { style: 'padding: 0.3rem 0.8rem; line-height: 1.4; word-wrap: break-word; white-space: normal; border-bottom: 1px dashed #eee' }
                  }"
                />
                <span class="mt-2 text-xs">Seleccione el tipo de campo que desea asignar.</span>
              </div>
            </div>

            <!-- Si el tipo de campo es lista, se muestra el campo de opciones -->
            <div v-if="['select', 'checkbox', 'radio', 'multiselect'].includes(_infoFields.dataType)" class="flex flex-col sm:flex-row border-b py-4">
              <div class="w-full sm:w-1/5 md:w-1/5 lg:w-1/5 sm:px-5 flex-shrink-0 flex-grow-0 min-w-0 flex flex-col items-end justify-center">
                <label class="text-gray-500 text-sm font-semibold text-left w-full sm:text-right ml-2">Opciones de la lista: <span class="text-red-600">*</span></label>
              </div>
              <div class="min-w-0 w-full sm:w-4/5 md:w-4/5 lg:w-4/5 flex-grow flex-shrink sm:px-5 sm:border-l sm:border-gray-200 relative">
                <Chips
                  id="choiceList"
                  v-model="_infoFields.choiceList"
                  separator=","
                  class="w-full"
                  :class="{ 'border-red-600': _formSubmitted && (!_infoFields.choiceList || _infoFields.choiceList.length === 0) }"
                  :allowDuplicate="false"
                  addOnBlur
                  :pt="{
                      container: { style: _formSubmitted && (!_infoFields.choiceList || _infoFields.choiceList.length === 0) ? 'width: 100%; border: 1px solid #e53e3e !important' : 'width: 100%;' },
                      input: { style: 'font-size: 0.75rem' },
                      inputToken: { style: 'font-size: 0.7rem; padding: 0' },
                      token: { style: 'font-size: 0.75rem; padding: 0rem 0.4rem' }
                    }"
                />
                <span class="mt-2 text-xs">Ingrese las opciones separadas por coma.</span>
              </div>
            </div>

            <!-- Si el tipo de campo es numero, se muestra el campo de opciones -->
            <div v-if="_infoFields.dataType === 'number'" class="flex flex-col sm:flex-row border-b py-4">
              <div class="w-full sm:w-1/5 md:w-1/5 lg:w-1/5 sm:px-5 flex-shrink-0 flex-grow-0 min-w-0 flex flex-col items-end justify-center">
                <label class="text-gray-500 text-sm font-semibold text-left w-full sm:text-right ml-2">Formato tipo número: <span class="text-red-600">*</span></label>
              </div>
              <div class="min-w-0 w-full sm:w-4/5 md:w-4/5 lg:w-4/5 flex-grow flex-shrink sm:px-5 sm:border-l sm:border-gray-200 relative">
                <Dropdown
                  class="w-full"
                  :class="{ 'border-red-600': _formSubmitted && !_infoFields.numberFormat }"
                  v-model="_infoFields.numberFormat"
                  :options="_listTypeNumberFormat"
                  optionLabel="label"
                  optionValue="value"
                  placeholder="Seleccione una opción"
                  :filter="true"
                  scrollHeight="300px"
                  filterPlaceholder="Buscar por nombre"
                  autoFilterFocus
                  :pt="{
                    root: { style: 'height: 32px' },
                    input: { style: 'font-size: 0.875rem; padding: 0.25rem 0.5rem' },
                    panel: { style: 'font-size: 0.75rem; max-width: 100px;' },
                    item: { style: 'padding: 0.3rem 0.8rem; line-height: 1.4; word-wrap: break-word; white-space: normal; border-bottom: 1px dashed #eee' }
                  }"
                />
                <span class="mt-2 text-xs">Seleccione el formato del número.</span>
              </div>
            </div>

            <!-- Campo requerido -->
            <div class="flex flex-col sm:flex-row border-b py-4">
              <div class="w-full sm:w-1/5 md:w-1/5 lg:w-1/5 sm:px-5 flex-shrink-0 flex-grow-0 min-w-0 flex flex-col items-end justify-center">
                <label class="text-gray-500 text-sm font-semibold text-left w-full sm:text-right ml-2">Campo requerido:</label>
              </div>
              <div class="min-w-0 w-full sm:w-4/5 md:w-4/5 lg:w-4/5 flex-grow flex-shrink sm:px-5 sm:border-l sm:border-gray-200 relative">
                <Checkbox class="w-full" v-model="_infoFields.isRequired" :binary="true" />
                <span class="mt-2 text-xs">Seleccione si el campo será obligatorio.</span>
              </div>
            </div>

            <!-- Campo único -->
            <div class="flex flex-col sm:flex-row border-b py-4">
              <div class="w-full sm:w-1/5 md:w-1/5 lg:w-1/5 sm:px-5 flex-shrink-0 flex-grow-0 min-w-0 flex flex-col items-end justify-center">
                <label class="text-gray-500 text-sm font-semibold text-left w-full sm:text-right ml-2">Campo único:</label>
              </div>
              <div class="min-w-0 w-full sm:w-4/5 md:w-4/5 lg:w-4/5 flex-grow flex-shrink sm:px-5 sm:border-l sm:border-gray-200 relative">
                <Checkbox class="w-full" v-model="_infoFields.isUnique" :binary="true" />
                <span class="mt-2 text-xs">Seleccione si el campo es único.</span>
              </div>
            </div>

            <!-- Estado -->
            <div class="flex flex-col sm:flex-row border-b py-4">
              <div class="w-full sm:w-1/5 md:w-1/5 lg:w-1/5 sm:px-5 flex-shrink-0 flex-grow-0 min-w-0 flex flex-col items-end justify-center">
                <label class="text-gray-500 text-sm font-semibold text-left w-full sm:text-right ml-2">Estado:</label>
              </div>
              <div class="min-w-0 w-full sm:w-4/5 md:w-4/5 lg:w-4/5 flex-grow flex-shrink sm:px-5 sm:border-l sm:border-gray-200 relative">
                <InputSwitch v-model="_infoFields.status" id="status" class="p-d-block"/>
                <span class="mt-2 text-xs">Seleccione el estado del campo.</span>
              </div>
            </div>

          </div>

        </div>
      </div>

    </Sidebar>
  </div>
</template>

<style scoped>

</style>
